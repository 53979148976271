:root {
  --blue-color-01: #01042E;
  --blue-color-02: rgba(1, 72, 97, 0.49);
  --blue-color-03: #014861;
  --blue-color-04: #012547;
  --blue-color-05: rgba(46, 139, 203, 0.53);
  --blue-color-06: rgba(23, 185, 203, 0.62);
  --blue-color-07: #034961;
  --blue-color-08: #2E8BCB;
  --blue-color-09: #50E9FF;
  --orange-color-01: #FE4F0D;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccdddd;
}