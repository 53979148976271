.modal {
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.modal-content {
    background-color: #fefefe;
    margin: 30px auto;
    border: 1px solid #888;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    display: flex;
    flex-direction: column;
}

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}
@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

.modal-header {
    background-color: #f1f1f1;
    padding: 2px 20px;
    border-bottom: 1px solid #ddd;
    position: relative;
    flex-shrink: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.close-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.close-text {
    color: var(--blue-color-03);
    font-size: 16px;
    margin-right: 1px;
    margin-bottom: 8px;
}

.close {
    color: var(--blue-color-03);
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
}

.close-container:hover .close-text,
.close-container:hover .close,
.close-container:focus .close-text,
.close-container:focus .close {
    color: #000;
    text-decoration: none;
}

.modal-body {
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
}

.full-modal {
    .modal-content {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .modal-content {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
    }
    
    .modal-header {
        padding: 10px 15px;
        height: 30px;
    }
}

@media only screen and (max-width: 992px) {
    .modal-content {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        margin: 20px;
    }
}