header {
    display: flex;
    justify-content: space-between;
    background-color: var(--blue-color-02);
    padding: 10px 20px;
    color: #fff;

    div {
        display: inline-flex;
    }

    p {
        font-size: 1.2rem;
        font-weight: 100;
        font-style: italic;
        letter-spacing: 0.5px;
        margin: 0;
        margin-top: 13px;
    }
}

@media only screen and (max-width: 768px) {
    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        p {
            font-size: 1rem;
            margin: 20px auto;
        }
    }
}