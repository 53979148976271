.login-container {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/images/background-img.svg');
  background-size: cover;
  background-position: center;

  &::after {
    content: '';
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(1, 4, 46, 0.6);
  }
}

.pycube-logo {
  position: absolute;
  bottom: 40px;
  left: 30px;
  width: 120px;
}

.title {
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: var(--blue-color-09);
  font-size: 4rem;
  font-style: italic;
  font-weight: 100;
  line-height: normal;
  letter-spacing: 1.92px;
}

input {
  box-sizing: border-box;
}

.login-page {
  max-width: 400px;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -30%);
  top: 30%;
  left: 50%;
  z-index: 1;
  background-color: rgba(232, 239, 251, 0.60);
  padding: 20px;
  border-radius: 10px;

  form {
    display: flex;
    flex-direction: column;
  }

  .input-container {
    display: flex;
  }

  input {
    padding: 10px;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    flex-grow: 1;
    border-radius: 10px;
    border: 0.5px solid #01042E;
  }

  button {
    padding: 10px;
    font-size: 1.2rem;
    margin-left: 10px;
    border-radius: 10px;
    border: 1px solid #01042E;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(29, 98, 137, 0.15);
    font-weight: 900;
    color: var(--orange-color-01);
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }
}

.ok-page {
  max-width: 400px;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -30%);
  top: 30%;
  left: 50%;
  z-index: 1;
  background-color: rgba(232, 239, 251, 1);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #6a7788;
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: 0px;
  }

  button {
    background-color: #fe4f0d;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 10px;
  }
}

.verification-page {
  max-width: 400px;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -30%);
  top: 30%;
  left: 50%;
  z-index: 1;
  background-color: rgba(232, 239, 251, 1);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    color: #fe4f0d;
    font-size: 2rem;
    margin: 0px 0px 20px;
  }

  p {
    color: #6a7788;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .email {
    margin-bottom: 20px;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1.2rem;
  }

  .captcha-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .cptcha {
      display: flex;
      align-items: center;
      background-color: #e9e9e9;
      margin-right: 15px;
      border-radius: 4px;
      color: #373e48;
      font-size: 1.2rem;

      span {
        padding: 0 15px;
        display: inline-block;
      }

      .refresh-captcha {
        background: none;
        border: none;
        color: #373e48;
        cursor: pointer;
        font-size: 1.2rem;
        padding: 0 10px;
      }
    }

    input {
      border-radius: 0 4px 4px 0;
      margin-bottom: 0;
      flex-grow: 1;
    }
  }

  button[type="submit"] {
    background-color: #fe4f0d;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
  }

  .resend-link {
    color: #fe4f0d;
    cursor: pointer;
  }
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  .title {
    bottom: 90px;
    left: 20px;
    font-size: 1.5rem;
  }

  .login-page,
  .verification-page,
  .ok-page {
    max-width: 300px;
  }
}

@media only screen and (max-width: 992px) {
  .title {
    font-size: 3rem;
  }
}