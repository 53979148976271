.tiles-wrapper {
    display: flex;
    justify-content: space-between;
}

.main-col-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px;
}

.item {
    display: inline-flex;
    background-color: var(--blue-color-02);
    justify-content: center;
    color: #fff;
    align-items: center;
    text-align: center;
    font-size: 0.9rem;
    transition: all .2s;
    cursor: pointer;

    &:hover {
        transform: scale(1.5);
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    }
}

.h-150 {
    height: 150px;
}

.h-300 {
    height: 300px;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.mr-20 {
    margin-right: 20px;
}

.mr-0 {
    margin-right: 0px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-0 {
    margin-bottom: 0px;
}

.row-flex {
    display: flex;
    flex-direction: row;
}

.blue-color-02 {
    background-color: var(--blue-color-02);
}

.blue-color-03 {
    background-color: var(--blue-color-03);
}

.blue-color-04 {
    background-color: var(--blue-color-04);
}

.blue-color-05 {
    background-color: var(--blue-color-05);
}

.blue-color-06 {
    background-color: var(--blue-color-06);
}

.blue-color-07 {
    background-color: var(--blue-color-07);
}

.blue-color-08 {
    background-color: var(--blue-color-08);
}

iframe {
    width: 100%;
    height: 100%;
}

span {
    margin: 10px;
    line-height: 1.5rem;
}


@media only screen and (max-width: 768px) {
    .tiles-wrapper {
        display: flex;
        flex-direction: column;

        &>div {
            margin: 0;
        }
    }

    .w-50 {
        width: 100%;
    }

    .row-flex {
        flex-direction: column;
    }
}

@media only screen and (max-width: 992px) {
    .tiles-wrapper {
        display: flex;
        flex-direction: column;

        &>div {
            margin: 0;
        }
    }
}